<template>
  <div class="account-settings-info-view">
    <a-row class="form-row" :gutter="16" style="margin-bottom: 20px;" v-if="currentUser.isResetPass === 1">
      <a-col :lg="6" :md="12" :sm="24">
        <a-tag color="red">注：请先修改密码，再访问该系统</a-tag>
      </a-col>
    </a-row>
    <a-row :gutter="16" type="flex" justify="start">
      <a-col :md="24" :lg="10">
        <a-form layout="vertical" :form="form" v-if="isShowForm">
          <a-form-item
            label="新密码"
            :validateStatus="checkParams.passStatus"
            :help="checkParams.passStatusMsg"
          >
            <a-input-password placeholder="请输入新密码" v-decorator="['pass', {initialValue: userInfo.pass, rules: [{required: true, validator: verifyPass, validateTrigger: 'change'}]}]" />
          </a-form-item>
          <a-form-item
            label="确认密码"
            :validateStatus="checkParams.repassStatus"
            :help="checkParams.repassStatusMsg"
          >
            <a-input-password placeholder="请输入再次输入新密码" v-decorator="['repass', {initialValue: userInfo.repass, rules: [{required: true, validator: verifyRePass, validateTrigger: 'change'}]}]" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="setUserPassApi">确认</a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { baseMixin } from '@/store/app-mixin'
import { setUserPass } from '@/api/user'
export default {
  mixins: [baseMixin],
  inject: ['reload'],
  components: {
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'repassForm' }),
      // cropper
      preview: {},
      userInfo: {
        repass: '',
        pass: ''
      },
      checkParams: {
        passStatus: 'success',
        passStatusMsg: null,
        isPass: false,
        repassStatus: 'success',
        repassStatusMsg: null,
        isRepass: false
      },
      userInfoParam: {},
      currentUser: {},
      isShowForm: true
    }
  },
  created () {
    this.currentUser = this.$store.state.user
    this.isShowForm = true
  },
  watch: {
    $route (val) {
      if (val.name === 'CenterUserPassword') {
        this.userInfo = {
          repass: '',
          pass: ''
        }
        this.checkParams.passStatus = 'success'
        this.checkParams.passStatusMsg = null
        this.checkParams.isPass = false
        this.checkParams.repassStatus = 'success'
        this.checkParams.repassStatusMsg = null
        this.checkParams.isRepass = false
        this.isShowForm = false
        this.$nextTick(function () {
          this.isShowForm = true
        })
      }
    }
  },
  methods: {
    setUserPassApi () {
      const _this = this
      if (!_this.checkParams.isRepass && !_this.checkParams.isPass) {
        return false
      }
      _this.userInfoParam.pass = _this.userInfo.pass
      _this.userInfoParam.repass = _this.userInfo.repass
      setUserPass(_this.userInfoParam).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('修改成功')
          _this.handleLogout()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    verifyRePass (rule, value, callback) {
      var repass = value.trim()
      this.userInfo.repass = repass
      if (repass === '') {
        this.checkParams.repassStatus = 'error'
        this.checkParams.repassStatusMsg = '请输入新密码'
        this.checkParams.isRepass = false
        callback(new Error('请输入再次输入新密码'))
      } else {
        if (repass.length < 6 || repass.length > 20) {
          this.checkParams.repassStatus = 'error'
          this.checkParams.repassStatusMsg = '密码长度为6-20'
          this.checkParams.isRepass = false
          callback(new Error('密码长度为6-20'))
        } else {
          if (this.userInfo.pass !== repass) {
            this.checkParams.repassStatus = 'error'
            this.checkParams.repassStatusMsg = '密码输入不一致'
            this.checkParams.isRepass = false
          } else {
            this.checkParams.repassStatus = 'success'
            this.checkParams.repassStatusMsg = null
            this.checkParams.isRepass = true
          }
        }
      }
    },
    verifyPass (rule, value, callback) {
      var pass = value.trim()
      this.userInfo.pass = pass
      if (pass === '') {
        this.checkParams.passStatus = 'error'
        this.checkParams.passStatusMsg = '请输入新密码'
        this.checkParams.isPass = false
        callback(new Error('请输入新密码'))
      } else {
        if (pass.length < 6 || pass.length > 20) {
          this.checkParams.passStatus = 'error'
          this.checkParams.passStatusMsg = '密码长度为6-20'
          this.checkParams.isPass = false
          callback(new Error('密码长度为6-20'))
        } else {
          this.checkParams.passStatus = 'success'
          this.checkParams.passStatusMsg = null
          this.checkParams.isPass = true
        }
      }
    },
    handleLogout () {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .avatar-upload-wrapper {
    height: 200px;
    width: 100%;
  }

  .ant-upload-preview {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;

    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      opacity: 0;
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
      }

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }

    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }
</style>
